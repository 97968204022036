import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const AgendaFormFilterPublics = ({OnChangeFilterPublic, AgendaFilterActivePublic}) =>
	<Select 
		className="generic-filters-select agenda-filters-select agenda-filters-select-publics" 
		name="agenda-filters-publics" 
		placeholder="Tous les publics" 
		options={SELECTS['publics']} 
		onChange={OnChangeFilterPublic} 
		value={AgendaFilterActivePublic}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default AgendaFormFilterPublics
