exports.others = [
	{ value: '', label: 'Tous les types d\'événements', className: 'option-other' },
	{ value: '101', label: 'Atelier', className: 'option-other' },
	{ value: '102', label: 'Braderie - Brocante', className: 'option-other' },
	{ value: '103', label: 'Cérémonie', className: 'option-other' },
	{ value: '104', label: 'Cinéma', className: 'option-other' },
	{ value: '105', label: 'Conférence - Rencontre', className: 'option-other' },
	{ value: '106', label: 'Conseil Municipal', className: 'option-other' },
	{ value: '107', label: 'Danse', className: 'option-other' },
	{ value: '108', label: 'Développement durable', className: 'option-other' },
	{ value: '138', label: 'Economie sociale et solidaire', className: 'option-other' },
	{ value: '109', label: 'Emploi', className: 'option-other' },
	{ value: '110', label: 'Exposition', className: 'option-other' },
	{ value: '111', label: 'Fête - Festival', className: 'option-other' },
	{ value: '112', label: 'Formation', className: 'option-other' },
	{ value: '113', label: 'Lecture', className: 'option-other' },
	{ value: '114', label: 'Mode', className: 'option-other' },
	{ value: '115', label: 'Musique', className: 'option-other' },
	{ value: '143', label: 'Rénovation urbaine', className: 'option-other' },
	{ value: '116', label: 'Réunion publique', className: 'option-other' },
	{ value: '117', label: 'Santé', className: 'option-other' },
	{ value: '118', label: 'Spectacle', className: 'option-other' },
	{ value: '119', label: 'Sport', className: 'option-other' },
	{ value: '120', label: 'Théâtre', className: 'option-other' },
	{ value: '121', label: 'Visite - Balade', className: 'option-other' },
];

exports.publics = [
	{ value: '', label: 'Tous les publics', className: 'option-public' },
	{ value: '132', label: 'Famille', className: 'option-public' },
	{ value: '133', label: 'Étudiant', className: 'option-public' },
	{ value: '134', label: 'Seniors', className: 'option-public' },
	{ value: '135', label: 'Tout public', className: 'option-public' },
	{ value: '136', label: 'Jeune Public', className: 'option-public' },
	{ value: '137', label: 'Professionnel', className: 'option-public' },
	{ value: '140', label: 'Enseignant', className: 'option-public' },
];

exports.quartiers = [
	{ value: '', label: 'Tous les quartiers', className: 'option-public' },
	{ value: '127', label: 'Quartiers Nord', className: 'option-public' },
	{ value: '128', label: 'Quartiers Sud', className: 'option-public' },
	{ value: '129', label: 'Quartiers Est', className: 'option-public' },
	{ value: '130', label: 'Quartiers Ouest', className: 'option-public' },
	{ value: '131', label: 'Quartiers Centre', className: 'option-public' },
];
