import React from 'react'
import {Link} from 'gatsby'
import moment from 'moment'

const getPostCategories = tagGroups => {
	let catShow = {}
	let categories = {}

	tagGroups && tagGroups.map(tagsChild => {
		return ['public', 'quartiers', 'participation'].includes(tagsChild.slug) ?
			tagsChild.tags.map(child =>
				categories[tagsChild.slug] = categories[tagsChild.slug] ? `${categories[tagsChild.slug]} . ${child.label}` : child.label
			):
			tagsChild.slug !== 'reseau-metropole-de-lille' && tagsChild.slug !== 'organise-par-la-ville-de-roubaix' && tagsChild.tags.map(child =>
				categories.other = categories.other ? `${categories.other} . ${child.label}` : child.label
			)
	})

	categories.public && (catShow.target = categories.public)
	categories.participation && (catShow.target = catShow.target ? `${catShow.target} . ${categories.participation.replace('Événement gratuit', 'Gratuit')}` : categories.participation.replace('Événement gratuit', 'Gratuit'))
	categories.other && (catShow.other = categories.other.replace('. Newsletter','').replace('. A la une','').replace('. A ne pas rater','').replace('. Site Zéro Déchet',''))

	return catShow
}

const AgendaListItem = ({postIndex, postItem}) => {
	const {title, originalImage, canonicalUrl, locationName, tagGroups, firstDate, lastDate} = postItem
	const postCatName = getPostCategories(tagGroups)

	return (
		<Link className="" to={canonicalUrl.replace('https://openagenda.com/roubaix/events/','/agenda/').replace('https://openagenda.com/roubaix/event/','/agenda/')} key={postIndex}>
			<div className="agenda-list-item-content agenda-list-item-">
				<div className="agenda-list-item-content-picture" style={{backgroundImage: `url(${originalImage})`}}></div>
				{postCatName.other && <p className="agenda-list-item-content-cateory">{postCatName.other}</p>}
				<div className="agenda-list-item-content-info">
					{title &&
						<h2 
							className="agenda-list-item-content-title"
							dangerouslySetInnerHTML={{ __html: title.fr }}
						></h2>
					}
					
					<div className="agenda-list-item-content-infos">
						<p className="agenda-list-item-content-location">{locationName}</p>
						{postCatName.target && <p className="agenda-list-item-content-target" >{postCatName.target}</p>}
					</div>
					<p className="agenda-list-item-content-date">
						<span className="agenda-list-item-content-date-begin" >{moment(firstDate).format('DD MMM')}</span>
						<span className="agenda-list-item-content-date-end" >{moment(lastDate).format('DD MMM')}</span>
					</p>
				</div>
			</div>
		</Link>
	)
}

export default AgendaListItem
