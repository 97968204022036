import React from 'react'

const AgendaSingleItemHeader = ({postItem}) =>
	<div className="agenda-single-item-category" >
		{postItem._source.terms_other && 
			<p className="agenda-single-item-category-field agenda-single-item-category-other">
				{postItem._source.terms_other.replace(', Newsletter','').replace(', A la une','').replace(', A ne pas rater','').replace(', Site Zéro Déchet','').split(', ').map((el, i) =>
					<span key={i} className="agenda-single-item-category-field-item agenda-single-item-category-other-item" >
						{el}
					</span>
				)}
			</p>
		}
		{postItem._source.post_location_name && 
			<p className="agenda-single-item-category-field agenda-single-item-category-location-name">
				<span className="agenda-single-item-category-field-item agenda-single-item-category-item-location-name">
					{postItem._source.post_location_name}
				</span>
			</p>
		}
		{postItem._source.post_partiticpation && 
			<p className="agenda-single-item-category-field agenda-single-item-category-partiticpation">
				<span className="agenda-single-item-category-field-item agenda-single-item-category-item-partiticpation">
					{postItem._source.post_partiticpation.replace('evenement-gratuit', 'Gratuit')}
				</span>
			</p>
		}
		{postItem._source.post_by_roubaix &&
			<p className="agenda-single-item-category-field agenda-single-item-category-pbr">
				<span className="agenda-single-item-category-field-item agenda-single-item-category-item-pbr">
					{postItem._source.post_by_roubaix}
				</span>
			</p>
		}
	</div>

export default AgendaSingleItemHeader