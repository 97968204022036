import React from 'react'

const AgendaFormFilterDatesButtons = ({location, OnClickDatePickerReset, current, OnClickDatePickerThisDay, OnClickDatePickerThisWeek, OnClickDatePickerThisWeekend}) =>
	<div className={`agenda-filters-dates-buttons${location === 'home' ? ' agenda-filters-home-dates-buttons' : ''}`}>
		{typeof OnClickDatePickerReset === "function" &&
			<button className={`agenda-filters-dates-reset${location === 'home' ? ' agenda-filters-home-dates-reset' : ''}`} onClick={OnClickDatePickerReset}>
				Toutes les dates
			</button>
		}
		{typeof OnClickDatePickerThisDay === "function" &&
			<button className={`agenda-filters-dates-day${location === 'home' ? ' agenda-filters-home-dates-day' : ''}${current === 'day' ? ' active' : ''}`} onClick={OnClickDatePickerThisDay}>
				Aujourd'hui
			</button>
		}
		{typeof OnClickDatePickerThisWeek === "function" &&
			<button className={`agenda-filters-dates-week${location === 'home' ? ' agenda-filters-home-dates-week' : ''}${current === 'week' ? ' active' : ''}`} onClick={OnClickDatePickerThisWeek}>
				Cette semaine
			</button>
		}
		{typeof OnClickDatePickerThisWeekend === "function" &&
			<button className={`agenda-filters-dates-weekend${location === 'home' ? ' agenda-filters-home-dates-weekend' : ''}${current === 'weekend' ? ' active' : ''}`} onClick={OnClickDatePickerThisWeekend}>
				Ce week-end
			</button>
		}
	</div>

export default AgendaFormFilterDatesButtons
