import React, { Component } from 'react'
import {Link} from 'gatsby'

class AgendaSingleItemDates extends Component {
	getPostDateBegin = () => {
		const firstDate = this.props.postItem._source.date_begin.split(' ')[0]
		if ( firstDate ) {
			const dateBegin = new Date( firstDate )
			return new Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'short', timeZone: 'Europe/Paris'}).format( dateBegin )
		}
		return ''
	}

	getPostDateEnd = () => {
		const firstDate = this.props.postItem._source.date_begin.split(' ')[0]
		const lastDate = this.props.postItem._source.date_end.split(' ')[0]
		if (lastDate) {
			const dateEnd = new Date( lastDate )
			const dateEndReturn = Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'short', timeZone: 'Europe/Paris'}).format( dateEnd )
			const dateBegin = new Date( firstDate )
			const dateBeginCheck = Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'short', timeZone: 'Europe/Paris'}).format( dateBegin )
			if ( dateEndReturn !== dateBeginCheck ) {
				return dateEndReturn
			} else {
				return this.props.postItem._source.date_begin.split(' ')[1]
			}
		}
		return ''
	}

	showEndAlert = () => {
		const lastDate = this.props.postItem._source.date_end.split(' ')[0]
		if (lastDate) {
			const currentTime = new Date();
			const currDate = new Date( currentTime.getMonth() + "/" + ( currentTime.getDate() ) + "/" + currentTime.getFullYear() + " 23:59" );
			const lastDateTime = new Date(lastDate);
			const milliseconds = ( lastDateTime.getTime() - currDate.getTime() );
			if ( milliseconds && milliseconds < 0 ) {
				return ( 
					<div className="agenda-covid">
						<p className="section-title"><span>Attention</span></p>
						<p className="section-text">
							<span className="text-bold">La date de cet évènement est passée, n’hésitez pas à consulter l’agenda en cliquant <Link className="agenda-covid-link" to="/agenda/">ici</Link></span><br />
						</p>
					</div>
				)
			} 
			return null
		}
	}

	render = () => {
		return (
			<p className="agenda-single-item-dates" >
				{this.showEndAlert()}
				<span className="agenda-single-item-dates-begin">{this.getPostDateBegin()}</span><span className="agenda-single-item-dates-sepa"> -- </span><span className="agenda-single-item-dates-end">{this.getPostDateEnd()}</span>
			</p>
		)
	}
}

export default AgendaSingleItemDates;
