import React from 'react'
import { Router } from "@reach/router"

import AgendaList from '../components/Agenda/List/AgendaList'
import AgendaSingle from '../components/Agenda/Single/AgendaSingle'

const AgendaResults = ({data}) =>
	<div>
		{typeof window !== 'undefined'  &&
			<Router>
				<AgendaList path="agenda/:myFilterType/:myFilterValue" />
				<AgendaList path="agenda" />
				<AgendaSingle path="agenda/:urlSingle" />
			</Router>
		}
	</div>

export default AgendaResults
