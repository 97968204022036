import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Container } from 'reactstrap'
import moment from 'moment'
import "isomorphic-fetch"

import * as loadingActions from '../../../state/actions/loading'
import { agendaFiltersOtherUpdate } from '../../../state/actions/agenda'
import Config from '../../../config/Config.json'
import Config_local from '../../../config/Config_local.json'
import '../../../config/Moment.js'

import AgendaBreadcrumb from './../AgendaBreadcrumb'
import AgendaAddEvent from "./../AgendaAddEvent"
import AgendaExportEvent from "./../AgendaExportEvent"
import AgendaForm from "./../Form/AgendaForm"
import AgendaFilterReset from './../Form/AgendaFilterReset';
import AgendaListItem from "./AgendaListItem"
import Pagination from "../../Pagination/Pagination"

// import scrollToComponent from 'react-scroll-to-component';

const config = {...Config, ...Config_local}

const appendUrl = (urlString, param, value) => urlString.length ? `&${param}=${value}` : `?${param}=${value}`

class AgendaList extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)

		const {mySearch,myFilterType,myFilterValue} = props

		let AgendaFilterStartDate = localStorage.getItem('AgendaFilterStartDate')
		if ( AgendaFilterStartDate ) {
			AgendaFilterStartDate = moment(new Date(AgendaFilterStartDate))
		} else {
			AgendaFilterStartDate = moment(new Date())
		}


		let AgendaFilterEndDate = localStorage.getItem('AgendaFilterEndDate')
		if ( AgendaFilterEndDate ) {
			AgendaFilterEndDate = moment(new Date(AgendaFilterEndDate))
		} else {
			AgendaFilterEndDate = moment(new Date(parseInt( moment( new Date()).format('YYYY') ) + 200, 12, 31, 0, 0, 0))
		}

		// Liste des filtres actifs
		this.state = {
			urlSingle: '',
			mysearch: mySearch && mySearch.length > 0 ? mySearch.trim() : (localStorage.getItem('AgendaFilterMySearch') || '    '),
			AgendaFilterActiveOther: localStorage.getItem('AgendaFilterActiveOther') || '',
			AgendaFilterActivePublic: localStorage.getItem('AgendaFilterActivePublic') || '',
			AgendaFilterActiveQuartier: localStorage.getItem('AgendaFilterActiveQuartier') || '',
			AgendaFilterActiveParticipation: localStorage.getItem('AgendaFilterActiveParticipation') || '',
			totals: false,
			offset: false,
			limit: false,
			page: localStorage.getItem('AgendaFilterPage') || 0,
			posts: 0,
			startDate: AgendaFilterStartDate,
			showStartDate: false,
			endDate: AgendaFilterEndDate,
			showEndDate: false,
			filterDateIsOpen: false,
			filterDateCurrent: localStorage.getItem('AgendaFilterDateCurrent') || "Toutes les dates",
			agendaResetShow: localStorage.getItem('AgendaFilterResetShow') || false,
		}

		if ( myFilterType === "dates" ) {
			this.state.showStartDate =  true;
			this.state.showEndDate =  true;
			if ( myFilterValue === "aujourd-hui" ) {
				this.state.startDate = moment(new Date());
				this.state.endDate = moment(new Date());
				this.state.filterDateCurrent = "Aujourd'hui";
				localStorage.setItem('AgendaFilterStartDate', moment(new Date()) )
				localStorage.setItem('AgendaFilterEndDate', moment(new Date()) )
				localStorage.setItem('AgendaFilterDateCurrent', "Aujourd'hui" )
			}
			if ( myFilterValue === "cette-semaine" ) {
				this.state.startDate = moment(new Date());
				this.state.endDate = moment(new Date()).startOf('week').isoWeekday(7);
				this.state.filterDateCurrent = "Cette semaine";
				localStorage.setItem('AgendaFilterStartDate', moment(new Date()) )
				localStorage.setItem('AgendaFilterEndDate', moment(new Date()).startOf('week').isoWeekday(7) )
				localStorage.setItem('AgendaFilterDateCurrent', "Cette semaine" )
			}
			if ( myFilterValue === "ce-weekend" ) {
				this.state.startDate = moment(Date()).startOf('week').isoWeekday(6);
				this.state.endDate = moment(new Date()).startOf('week').isoWeekday(7);
				this.state.filterDateCurrent = "Ce week-end";
				localStorage.setItem('AgendaFilterStartDate', moment(Date()).startOf('week').isoWeekday(6) )
				localStorage.setItem('AgendaFilterEndDate', moment(new Date()).startOf('week').isoWeekday(7) )
				localStorage.setItem('AgendaFilterDateCurrent', "Ce week-end" )
			}
			this.state.agendaResetShow =  true;
			localStorage.setItem('AgendaFilterResetShow', true)
		}

		if ( myFilterValue && myFilterValue.length > 0 ) {
			if ( myFilterType === "tag" ) {
				this.state.AgendaFilterActiveOther = myFilterValue;
				this.state.agendaResetShow =  true;
				localStorage.setItem('AgendaFilterActiveOther', myFilterValue )
				localStorage.setItem('AgendaFilterResetShow', true)
				localStorage.setItem('AgendaFilterPage', 0);
			}
			if ( myFilterType === "public" ) {
				this.state.AgendaFilterActivePublic = myFilterValue;
				this.state.agendaResetShow =  true;
				localStorage.setItem('AgendaFilterActivePublic', myFilterValue )
				localStorage.setItem('AgendaFilterResetShow', true)
				localStorage.setItem('AgendaFilterPage', 0);
			}
			if ( myFilterType === "quartier" ) {
				this.state.AgendaFilterActiveQuartier = myFilterValue;
				this.state.agendaResetShow =  true;
				localStorage.setItem('AgendaFilterActiveQuartier', myFilterValue )
				localStorage.setItem('AgendaFilterResetShow', true)
				localStorage.setItem('AgendaFilterPage', 0);
			}
			if ( myFilterType === "participation" ) {
				this.state.AgendaFilterActiveParticipation = myFilterValue;
				this.state.agendaResetShow =  true;
				localStorage.setItem('AgendaFilterActiveParticipation', myFilterValue )
				localStorage.setItem('AgendaFilterResetShow', true)
				localStorage.setItem('AgendaFilterPage', 0);
			}
		}

		//on bloque la boucle d'une touche laissée enfoncée
		this.onKeyDown = false
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn} = this.props

		this.launchSearch()
		loadingOn('AgendaList')
	}

	componentWillUnmount() {
		const {loadingOff} = this.props

		loadingOff('AgendaList')
		this._isMounted = false
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {loadingOn} = this.props

		if(prevState.mysearch !== this.state.mysearch
			|| prevState.AgendaFilterActiveOther !== this.state.AgendaFilterActiveOther
			|| prevState.AgendaFilterActivePublic !== this.state.AgendaFilterActivePublic
			|| prevState.AgendaFilterActiveQuartier !== this.state.AgendaFilterActiveQuartier
			|| prevState.AgendaFilterActiveParticipation !== this.state.AgendaFilterActiveParticipation
			|| prevState.startDate.unix() !== this.state.startDate.unix()
			|| prevState.endDate.unix() !== this.state.endDate.unix()
			|| prevState.page !== this.state.page
		){
			this.launchSearch()
			loadingOn('AgendaList')
		}
	}

	launchSearch(){
		const {mysearch, AgendaFilterActiveOther, AgendaFilterActivePublic, AgendaFilterActiveQuartier, AgendaFilterActiveParticipation, startDate, endDate, page} = this.state
		let conf = {...config}

		conf.openAgendaFluxFilters = ""

		mysearch.trim().length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'search', mysearch))
		AgendaFilterActiveOther.length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'other', AgendaFilterActiveOther))
		AgendaFilterActivePublic.length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'public', AgendaFilterActivePublic))
		AgendaFilterActiveQuartier.length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'quartier', AgendaFilterActiveQuartier))
		AgendaFilterActiveParticipation.length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'participation', AgendaFilterActiveParticipation))
		startDate.isValid() && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'eventStart', startDate.format('MM/DD/YYYY')))
		endDate.isValid() && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'eventStop', endDate.format('MM/DD/YYYY')))
		page.length && (conf.openAgendaFluxFilters += appendUrl(conf.openAgendaFluxFilters, 'page', page))
		if(conf.openAgendaFluxFilters.length){
			fetch(`${conf.openAgendaFlux}${conf.openAgendaFluxFilters}`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					method: "POST"
				}
			).then(response => {
				if (response.status && response.status > 200 ) {
					console.warn(response)
				} else {
					return response.json()
				}
			}).then(results => {
				const {total, offset, limit, events} = results
				const {loadingOff} = this.props

				this._isMounted && this.setState({ 
					totals: total, 
					offset, 
					limit, 
					posts: events 
				})
				this.agendaOnPaginateScroll()
				loadingOff('AgendaList')
			}).catch(error => console.error("error", error))
		}
	}

	agendaOnChangeFilterCats = (val)  =>  {
		agendaFiltersOtherUpdate( val.trim() )
		this.setState({
			AgendaFilterActiveOther: val.trim(),
			agendaResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('AgendaFilterActiveOther', val.trim());
			localStorage.setItem('AgendaFilterPage', 0);
			localStorage.setItem('AgendaFilterResetShow', true);
		})
	}

	agendaOnChangeFilterPublic = (val) => {
		this.setState({
			AgendaFilterActivePublic: val.trim(),
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterActivePublic', val.trim());
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', true);
	}

	agendaOnChangeFilterQuartier = (val) => {
		this.setState({
			AgendaFilterActiveQuartier: val.trim(),
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterActiveQuartier', val.trim());
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', true);
	}

	agendaOnChangeFilterParticipation = ({target}) => {
		const {value, checked} = target
		this.setState({
			AgendaFilterActiveParticipation: (checked ? value.trim() : ''),
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterActiveParticipation', (checked ? value.trim() : ''));
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', true);
	}

	agendaOnChange = (e) => {
		e.preventDefault()
		return false
	}

	agendaOnKeyDown = ({target}) => {
		if(!this.onKeyDown){
			this.onKeyDown = true
		}
	}
	
	agendaOnKeyUp = ({keyCode, target}) => {
		this.onKeyDown = false
		if(keyCode === 13) {
			this.launchSearch()
		}else{
			const {value} = target
			this.setState({
				mysearch: value.trim()
			})
		}
	}

	agendaOnReset = (e) => {
		e.preventDefault()
		this.setState({
			AgendaFilterActiveOther: '',
			AgendaFilterActivePublic: '',
			AgendaFilterActiveQuartier: '',
			AgendaFilterActiveParticipation: '',
			startDate: moment(new Date()),
			showStartDate: false,
			endDate: moment(new Date(2222, 12, 31, 0, 0, 0)),
			showEndDate: false,
			filterDateCurrent: "Toutes les dates",
			agendaResetShow: false,
			page: 0
		})
		localStorage.setItem('AgendaFilterActiveOther', '');
		localStorage.setItem('AgendaFilterActivePublic', '');
		localStorage.setItem('AgendaFilterActiveQuartier', '');
		localStorage.setItem('AgendaFilterActiveParticipation', '');
		localStorage.setItem('AgendaFilterStartDate', moment(new Date()));
		localStorage.setItem('AgendaFilterEndDate', moment(new Date(parseInt( moment( new Date()).format('YYYY') ) + 200, 12, 31, 0, 0, 0)));
		localStorage.setItem('AgendaFilterDateCurrent', "Toutes les dates");
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnSubmit = (e) => {
		e.preventDefault()
		this.agendaOnSubmitScrollToTop()
	}


	agendaOnSubmitScrollToTop = () => {
		// scrollToComponent(this.Blue, { offset: 0, align: 'top', duration: 800, ease:'inCirc'});
		if (typeof window !== 'undefined') {
			window.scroll(0, document.querySelector(".agenda-add-event").getBoundingClientRect().top)
			window.scroll(0, document.querySelector(".agenda-add-event").getBoundingClientRect().top)
		}
	}


	agendaOnPaginateScroll = () => {
		// scrollToComponent(this.Blue, { offset: 0, align: 'top', duration: 800, ease:'inCirc'});
		if (typeof window !== 'undefined') {
			window.scroll(0, document.querySelector(".agenda-filters").getBoundingClientRect().top)
			window.scroll(0, document.querySelector(".agenda-filters").getBoundingClientRect().top)
		}
	}

	agendaFormFilters = ({target}) => {
		const {value} = target
		this.setState({
			mysearch: value.trim(),
			page: 0
		})
		localStorage.setItem('AgendaFilterMySearch', value.trim());
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnClickDatePickerOpenClose = (e) => {
		e.preventDefault()
		this.setState({
			filterDateIsOpen: !this.state.filterDateIsOpen
		})
	}

	agendaOnClickDatePickerReset = (e) => {
		e.preventDefault()
		this.setState({
			startDate: moment(new Date().toString()),
			showStartDate: false,
			endDate: moment(new Date((new Date().getFullYear()+200), 12, 31, 0, 0, 0).toString()),
			showEndDate: false,
			filterDateCurrent: "Toutes les dates",
			filterDateIsOpen: false,
			page: 0
		});
		localStorage.setItem('AgendaFilterStartDate', moment(new Date()));
		localStorage.setItem('AgendaFilterEndDate', moment(new Date(parseInt( moment( new Date()).format('YYYY') ) + 200, 12, 31, 0, 0, 0)));
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterDateCurrent', "Toutes les dates");
	}

	agendaOnClickDatePickerThisDay = (e) => {
		e.preventDefault()
		this.setState({
			startDate: moment(new Date()),
			showStartDate: true,
			endDate: moment(new Date()),
			showEndDate: true,
			filterDateCurrent: "Aujourd'hui",
			filterDateIsOpen: false,
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterStartDate', moment(new Date()) )
		localStorage.setItem('AgendaFilterEndDate', moment(new Date()) )
		localStorage.setItem('AgendaFilterDateCurrent', "Aujourd'hui" )
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnClickDatePickerThisWeek = (e) => {
		e.preventDefault()
		this.setState({
			startDate: moment(new Date()),
			showStartDate: true,
			endDate: moment(new Date()).startOf('week').isoWeekday(7),
			showEndDate: true,
			filterDateCurrent: "Cette semaine",
			filterDateIsOpen: false,
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterStartDate', moment(new Date()) )
		localStorage.setItem('AgendaFilterEndDate', moment(new Date()).startOf('week').isoWeekday(7) )
		localStorage.setItem('AgendaFilterDateCurrent', "Cette semaine" )
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnClickDatePickerThisWeekend = (e) => {
		e.preventDefault()
		this.setState({
			startDate: moment(Date()).startOf('week').isoWeekday(6),
			showStartDate: true,
			endDate: moment(Date()).startOf('week').isoWeekday(7),
			showEndDate: true,
			filterDateCurrent: "Ce week-end",
			filterDateIsOpen: false,
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterStartDate', moment(Date()).startOf('week').isoWeekday(6) )
		localStorage.setItem('AgendaFilterEndDate', moment(new Date()).startOf('week').isoWeekday(7) )
		localStorage.setItem('AgendaFilterDateCurrent', "Ce week-end" )
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnChangeDatePickerStart = (date) => {
		this.setState({
			startDate: date,
			showStartDate: true,
			filterDateCurrent: `${date.format('DD/MM/YYYY')} - ${this.state.endDate.format('DD/MM/YYYY')}`,
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterStartDate', date )
		localStorage.setItem('AgendaFilterDateCurrent', `${date.format('DD/MM/YYYY')} - ${this.state.endDate.format('DD/MM/YYYY')}` )
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnChangeDatePickerEnd = (date) => {
		this.setState({
			endDate: date,
			filterDateCurrent: `${this.state.startDate.format('DD/MM/YYYY')} - ${date.format('DD/MM/YYYY')}`,
			filterDateIsOpen: false,
			agendaResetShow: true,
			page: 0
		})
		localStorage.setItem('AgendaFilterEndDate', date )
		localStorage.setItem('AgendaFilterDateCurrent', `${this.state.startDate.format('DD/MM/YYYY')} - ${date.format('DD/MM/YYYY')}` )
		localStorage.setItem('AgendaFilterPage', 0);
		localStorage.setItem('AgendaFilterResetShow', false);
	}

	agendaOnClickPaginate = (e) => {
		e.preventDefault()
		this.setState({
			page: e.target.getAttribute('data-num-page')
		})
		localStorage.setItem('AgendaFilterPage', e.target.getAttribute('data-num-page'));
	}

	agendaOnClickPopin = (e) => {
		const isClickOnPopin = e.target.closest('.agenda-filters-dates-popin') ? true : false
		if (this.state.filterDateIsOpen) {
			if( isClickOnPopin ) return
			this.setState({
				filterDateIsOpen: false,
			})
		}
	}

	render(){
		const {posts, totals, page} = this.state

		return (
			<div>
				<Container>
					<Helmet>
						<title>Sortir à roubaix</title>
					</Helmet>
					<div className="offline-fallback-fix-no-idea-why"></div>
					<AgendaBreadcrumb />
					{/* <div className="agenda-covid py-md-5 ">
						<p className="section-title"><span>Se divertir (aussi) à la maison</span></p>
						<p className="section-text">
							<span className="text-bold">Les sorties sont à nouveau possibles et certaines structures roubaisiennes sont déjà prêtes à vous accueillir!</span><br />
							Les autres ne sont pas en reste et mettent tout en oeuvre pour vous recevoir dans le respect des consignes sanitaires.
						</p>
						<p className="section-text">
							En attendant, <Link className="agenda-covid-link" to="/que-faire-a-la-maison/">elles continuent de vous divertir en ligne, </Link>pour des moments joyeux à la maison.
						</p>
					</div> */}
					<div id="agenda" className="agenda" onClick={this.agendaOnClickPopin} onKeyPress={this.agendaOnClickPopin} role="button" tabIndex={0}>
						<AgendaForm 
							AgendaFilterActiveOther={this.state.AgendaFilterActiveOther} 
							AgendaFilterActivePublic={this.state.AgendaFilterActivePublic} 
							AgendaFilterActiveQuartier={this.state.AgendaFilterActiveQuartier} 
							onSubmit={this.agendaOnSubmit} 
							OnChangeFilterCats={this.agendaOnChangeFilterCats} 
							OnChangeFilterParticipation={this.agendaOnChangeFilterParticipation} 
							OnChangeFilterPublic={this.agendaOnChangeFilterPublic} 
							OnChangeFilterQuartier={this.agendaOnChangeFilterQuartier} 
							OnClickDatePickerOpenClose={this.agendaOnClickDatePickerOpenClose} 
							FilterDateIsOpen={this.state.filterDateIsOpen} 
							FilterDateCurrent={this.state.filterDateCurrent} 
							OnClickDatePickerReset={this.agendaOnClickDatePickerReset} 
							OnClickDatePickerThisDay={this.agendaOnClickDatePickerThisDay} 
							OnClickDatePickerThisWeek={this.agendaOnClickDatePickerThisWeek} 
							OnClickDatePickerThisWeekend={this.agendaOnClickDatePickerThisWeekend} 
							datePickerStartValue={this.state.startDate} 
							datePickerShowStartValue={this.state.showStartDate} 
							OnChangeDatePickerStart={this.agendaOnChangeDatePickerStart} 
							datePickerEndValue={this.state.endDate} 
							datePickerShowEndValue={this.state.showEndDate} 
							OnChangeDatePickerEnd={this.agendaOnChangeDatePickerEnd} 
							placeholder="Chercher une sortie" 
							type="text" 
							onKeyDown={this.agendaOnKeyDown} 
							onKeyUp={this.agendaOnKeyUp} 
							onChange={this.agendaOnChange} 
						/>

						<h1 className="agenda-result">
							{this.state.totals ? 
								`${this.state.totals} résultat${this.state.totals > 1 ? 's' : ''} pour votre recherche ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}` :
								`Pas de résultat pour votre recherche  ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}`
							}
						</h1>
						<AgendaFilterReset AgendaOnReset={this.agendaOnReset} AgendaResetShow={this.state.agendaResetShow} />
						<AgendaAddEvent />
						<section className='agenda-anchor' ref={(section) => { this.Blue = section; }}>&nbsp;</section>

						<ul id="agenda-list" className="agenda-list list-unstyled">
							{posts.length > 0 && posts.map((post, index) =>
								<li className="agenda-list-item" key={index}>
									<AgendaListItem 
										postIndex={index} 
										postItem={post} 
									/>
								</li>
							)}
							{!posts.length > 0 && Array(4).fill('').map((u, index) => <li className="agenda-list-item" key={index} />)}
						</ul>

						<div className="agenda-list-footer container">
							<div className="row">
								<div className="col-3 d-flex justify-content-start">
									<AgendaExportEvent oaUrl="https://openagenda.com/roubaix/actions/?lang=fr" oaLabel="Exporter l'agenda"/>
								</div>
								<div className="col-6 d-flex align-items-center">
									<Pagination 
										offset={config.agendaPagination.offset} 
										limit={config.agendaPagination.limit} 
										totals={totals} 
										currentPage={page}
										OnClickPaginate={this.agendaOnClickPaginate}
									/>
								</div>
								<div className="col-3 d-flex justify-content-end align-items-center">
									<AgendaAddEvent />
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

export default connect(null, loadingActions)(AgendaList)
