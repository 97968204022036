import React from 'react'

const AgendaSingleItemDatesListItem = ({monthTitle, startDay, timeArray, OnClickPrev, OnClickNext}) =>
	<>
		{monthTitle &&
			<p className="agenda-single-calendar-month-title" >
				<span className="agenda-single-calendar-month-title-prev" onClick={OnClickPrev} onKeyPress={OnClickPrev} role="button" tabIndex={0} >
					prev
				</span>
				<span className="agenda-single-calendar-month-title-name">
					{monthTitle}
				</span>
				<span className="agenda-single-calendar-month-title-next" onClick={OnClickNext} onKeyPress={OnClickNext} role="button" tabIndex={0} >
					next
				</span>
			</p>
		}
		{startDay &&
			<p className="agenda-single-calendar-month-day">
				<span className="agenda-single-calendar-month-day-first">
					{startDay}
				</span>
				<span className="agenda-single-calendar-month-day-last">
					{timeArray && timeArray.map((time, index) => 
						<span key={index}>{time[0]} - {time[1]}</span>
					)}
				</span>
			</p>
		}
	</>

export default AgendaSingleItemDatesListItem