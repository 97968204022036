import React, { Component } from 'react'
import v4 from 'uuid/v4'

class AgendaSingleItemRegistrationUrl extends Component {

	isPostRegistrationUrlNumber = (link) => {
		return (link && Number.isInteger(parseInt(link.replace('tel:','').trim())))
	}

	isPostRegistrationUrlMail = (link) => {
		return link.indexOf('@') >= 0;
	}

	getPostRegistrationUrlLabel = (link) => {
		if (link) {
			if (this.isPostRegistrationUrlNumber(link)) {
				return "Tél: "+link.replace('tel:','').trim()
			} else if(this.isPostRegistrationUrlMail(link)) {
				return "Contact mail"
			} else {
				return "S'inscrire / réserver"
			}
		}
		return ''
	}

	getPostRegistrationUrlLink = (link) => {
		if (link) {
			if (this.isPostRegistrationUrlNumber(link)) {
				return "tel:" + link.replace('tel:','').trim()
			} else if (this.isPostRegistrationUrlMail(link)) {
				return "mailto:" + link.replace('mailto:','').trim()
			} else {
				return link.trim()
			}
		}
		return ''
	}

	getPostRegistrationUrl = () => {
		if (this.props.postItem._source.post_registration_url ) {
			return this.props.postItem._source.post_registration_url
		}
		return ''
	}

	render = () => {
		const listLink = String(this.props.postItem._source.post_registration_url).split(',');
		return (
			<>
				{this.props.postItem._source.post_registration_url &&
					<p className="agenda-single-item-field agenda-single-item-registration-url" >
						<span className="agenda-single-item-field-label agenda-single-item-registration-url-label">Pour plus d'infos:</span>
						{listLink.length > 0 && listLink.map((link, index) =>
							<a key={v4()} className="agenda-single-item-field-link" href={this.getPostRegistrationUrlLink(link)} target="_blank" rel="noopener noreferrer" >{this.getPostRegistrationUrlLabel(link)}</a>
						)}
					</p>
				}
			</>
		)
	}
}

export default AgendaSingleItemRegistrationUrl;