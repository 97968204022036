import React, { Component } from 'react'
import v4 from 'uuid/v4'

import AgendaSingleItemDatesListItem from "./AgendaSingleItemDatesListItem"


class AgendaSingleItemDatesList extends Component {
	constructor(props, context) {
		super(props, context);
		const getMonth = JSON.parse(atob(this.props.postItem._source.dates_list.toString()))
		this.state = {
			currentMonth: Number(getMonth[0][0].month),
		}
		this.minMonth = Number(getMonth[0][0].month)
		this.maxMonth = Number(getMonth[0][(getMonth[0].length-1)].month)
	}

	calendarOnClickPrev = () => {
		let current = document.querySelector('.agenda-single-calendar-month.current')
		let prev = null
		if ( current ) {
			current = current.getAttribute('class').replace('agenda-single-calendar-month','')
			current = current.replace('current','')
			current = current.replace('month-item-','')
			current = current.replace(' ','')
			prev = Number( current ) - 1
			if ( prev < 1 ) {
				prev = Number( document.querySelectorAll('.agenda-single-calendar-month').length )
			}
		}
		document.querySelector('.agenda-single-calendar-month.current').classList.remove('current')
		document.querySelector('.agenda-single-calendar-month.month-item-' + prev).classList.add('current')
	}

	calendarOnClickNext = () => {
		let current = document.querySelector('.agenda-single-calendar-month.current')
		let next = null
		if ( current ) {
			current = current.getAttribute('class').replace('agenda-single-calendar-month','')
			current = current.replace('current','')
			current = current.replace('month-item-','')
			current = current.replace(' ','')
			next = Number( current ) + 1
			if ( next > Number( document.querySelectorAll('.agenda-single-calendar-month').length ) ) {
				next = 1
			}
		}
		document.querySelector('.agenda-single-calendar-month.current').classList.remove('current')
		document.querySelector('.agenda-single-calendar-month.month-item-' + next).classList.add('current')
	}

	getPostDatesList = () => {
		const datesList = []
		if (this.props.postItem._source.dates_list) {
			let json = this.props.postItem._source.dates_list.toString()
			let dateStart = null
			let monthIndex = null
			let dayIndex = null
			let yearIndex = null
			try {
				json = JSON.parse(atob(json))
				json.map((dateNivOne, indexOne) => {
					dateNivOne.map((dateNivTwo, indexTwo) => {
						dateStart = new Date(dateNivTwo.date[0] * 1000)
						monthIndex = Number(dateNivTwo.month)
						dayIndex = Number(Intl.DateTimeFormat('fr-FR', {day: '2-digit'}).format(dateStart))
						yearIndex = Number(Intl.DateTimeFormat('fr-FR', {year: 'numeric'}).format(dateStart))
						if ( !(datesList[yearIndex] !== undefined) ) {
							datesList[yearIndex] = []
						}
						if ( !(datesList[yearIndex][monthIndex] !== undefined) ) {
							datesList[yearIndex][monthIndex] = []
						}
						if ( !datesList[yearIndex][monthIndex][dayIndex] ) {
							datesList[yearIndex][monthIndex][dayIndex] = []
						}
						datesList[yearIndex][monthIndex][dayIndex].push(dateNivTwo.date[0])
						datesList[yearIndex][monthIndex][dayIndex].push(dateNivTwo.date[1])
						return ''
					})
					return ''
				})
			} catch (e) {
				console.error("Parsing error:", e); 
			}
		}
		return datesList
	}

	getPostDatesListShowMonth = (month,monthList) => {
		let firstKey = 0
		monthList.forEach( (element,index) =>  {
			if ( firstKey === 0 ) { firstKey = index }
		})
		const date = new Date(monthList[firstKey][0]*1000);
		date.setMonth(month-1)
		return Intl.DateTimeFormat('fr-FR', {month: 'long', year: 'numeric'}).format(date)
	}

	getPostDatesListShowMonthList = (monthList) => {
		let start = null
		let startDay = null
		let end = null
		return monthList.map((week, index) => {
			const timeArray = [];
			if (week && week.length > 0) {
				let hourIndex = 0;
				let hourNewIndex = 0;
				for (hourIndex = 0; hourIndex < week.length; hourIndex=hourIndex+2) {
					start = new Date(week[hourIndex]*1000)
					startDay = Intl.DateTimeFormat('fr-FR', {weekday: 'long', day: '2-digit'}).format(start)
					if ( !timeArray[hourNewIndex] ) {
						timeArray[hourNewIndex] = []
					}
					timeArray[hourNewIndex][0] = String(Intl.DateTimeFormat('fr-FR', {hour: '2-digit', minute: '2-digit'}).format(start)).replace(":","h")

					end = new Date(week[hourIndex+1]*1000)
					timeArray[hourNewIndex][1] = String(Intl.DateTimeFormat('fr-FR', {hour: '2-digit', minute: '2-digit'}).format(end)).replace(":","h")
					hourNewIndex++;
				}
			}

			return <AgendaSingleItemDatesListItem key={index} startDay={startDay} timeArray={timeArray} />
		})
	}

	getPostDatesListShow = () => {
		const datesList = this.getPostDatesList()
		let cpt = 1
		if ( datesList.length > 0 ) {
			return datesList.map((yearList, index) => {
				return yearList.map((month, index) => {
					const monthTitle = this.getPostDatesListShowMonth(index,month)
					const MonthList = this.getPostDatesListShowMonthList(month)
					let currentShow = 'agenda-single-calendar-month month-item-' + cpt;

					if ( this.state.currentMonth === index) {
						currentShow = 'agenda-single-calendar-month current month-item-' + cpt;
					}
					cpt = cpt + 1
					return (
						<div className={currentShow} key={v4()}>
							<AgendaSingleItemDatesListItem 
								monthTitle={monthTitle} 
								OnClickPrev={this.calendarOnClickPrev} 
								OnClickNext={this.calendarOnClickNext}
								itemRef={cpt}
							/>
							<div className="agenda-single-calendar-month-days" >{MonthList}</div>
						</div>
					)
				})
			})
		} else {
			return ''
		}
	}

	render = () => {
		return (
			<div className="agenda-single-calendar" >
				{this.getPostDatesListShow()}
			</div>
		)
	}
}

export default AgendaSingleItemDatesList;
