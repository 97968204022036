import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'

import AgendaSingleItemDates from "./AgendaSingleItemDates"
import AgendaSingleItemHeader from "./AgendaSingleItemHeader"
import AgendaSingleItemDatesList from "./AgendaSingleItemDatesList"
import AgendaSingleItemRegistrationUrl from "./AgendaSingleItemRegistrationUrl"
import SocialShare from "../../SocialShare"

import AgendaBreadcrumb from '../AgendaBreadcrumb'
import ScrollTop from '../../ScrollTop'
import AgendaExportEvent from "./../AgendaExportEvent"
import AgendaAddEvent from "./../AgendaAddEvent"

import { getCookiebox } from '../../../state/reducers/cookiebox'
import { iframeParse } from '../../../utils/functions'


const AgendaSingleItem = ({postItem, shareUrl, cookiebox}) => 
	<div>
		{postItem && postItem._source &&
			<div>
				<Helmet>
					<title>{postItem._source.post_title || ''}</title>
				</Helmet>
				<AgendaBreadcrumb SingleTitle={postItem._source.post_title || ''} />
				<Container className="agenda-single-item">
					<Row>
						<Col tag="aside" lg="4" md="12" >
							<div className="anchors-sidebar h-100 pr-5">
								<div className="bg-light h-100 agenda-single-item-left">
									{postItem._source.latitude && postItem._source.longitude &&
										<p className="agenda-single-item-field agenda-single-item-location" >
											<a rel="noopener noreferrer" href={`http://maps.google.fr/maps?f=q&hl=fr&q=${postItem._source.latitude},${postItem._source.longitude}`} target="_blank" >
												{postItem._source.post_location_name}<br />
												{postItem._source.post_location_address}
											</a>
										</p>
									}
									{postItem._source.post_pictoaccess && <div className="text-center"><iframe className="agenda-single-item-pictoaccess" title="Indication PicoAccess" src={postItem._source.post_pictoaccess} width="auto" border="0" ></iframe></div>}
									{postItem._source.terms_quartier && <p className="agenda-single-item-field agenda-single-item-quartier" >{postItem._source.terms_quartier}</p>}
									{postItem._source.terms_public && <p className="agenda-single-item-field agenda-single-item-public" >{postItem._source.terms_public}</p>}
									{postItem._source.post_conditions && <p className="agenda-single-item-field agenda-single-item-partiticpation">{postItem._source.post_conditions}</p>}
									<AgendaSingleItemRegistrationUrl postItem={postItem}/>
									<SocialShare shareUrl={shareUrl}/>
									<AgendaExportEvent oaUrl={postItem._source.permalink+"/action?lang=fr"} oaLabel="Ajouter à mon agenda"/>
									<AgendaAddEvent />
								</div>
							</div>
						</Col>
						<Col tag="article" md="12" lg="8" className="py-md-5 page-content position-relative">
							<div className="agenda-single-item-right" >
								<Link to="/agenda/" className="agenda-single-link-back d-lg-none d-md-none d-sm-block d-xs-block " >Retourner à l'agenda</Link>
								{/* <div className="agenda-covid">
									<p className="section-title"><span>Se divertir (aussi) à la maison</span></p>
									<p className="section-text">
										<span className="text-bold">Les sorties sont à nouveau possibles et certaines structures roubaisiennes sont déjà prêtes à vous accueillir!</span><br />
										Les autres ne sont pas en reste et mettent tout en oeuvre pour vous recevoir dans le respect des consignes sanitaires.
									</p>
									<p className="section-text">
										En attendant, <Link className="agenda-covid-link" to="/que-faire-a-la-maison/">elles continuent de vous divertir en ligne, </Link>pour des moments joyeux à la maison.
									</p>
								</div> */}
								<AgendaSingleItemDates postItem={postItem}/>
								{postItem._source.post_title && <h1 className="agenda-single-item-title" >{postItem._source.post_title}</h1>}
								<AgendaSingleItemHeader postItem={postItem}/>
								{postItem._source.post_original_image &&
									<p className="agenda-single-item-picture" >
										<span className="agenda-single-item-picture-flou" style={{backgroundImage: 'url(' +postItem._source.post_original_image + ')'}} ></span>
										<img alt={postItem._source.post_title || ''} src={postItem._source.post_original_image} />
									</p>
								}
								{postItem._source.post_excerpt &&
									<p className="agenda-single-item-field agenda-single-item-excerpt" >
										{cookiebox ? iframeParse(postItem._source.post_excerpt) : postItem._source.post_excerpt}
									</p>
								}
								{postItem._source.post_content &&
									<div
										className="agenda-single-item-content"
										dangerouslySetInnerHTML={{ __html: cookiebox ? iframeParse(postItem._source.post_content) : postItem._source.post_content }}
									></div>
								}
								<AgendaSingleItemDatesList postItem={postItem} />
							</div>
							<ScrollTop/>
						</Col>
					</Row>
				</Container>
			</div>
		}
	</div>

const mapStateToProps = state => ({
	cookiebox: getCookiebox(state)
})

export default connect(mapStateToProps)(AgendaSingleItem)
