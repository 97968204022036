import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const AgendaFormFilterQuartiers = ({OnChangeFilterQuartier, AgendaFilterActiveQuartier}) =>
	<Select 
		className="generic-filters-select agenda-filters-select agenda-filters-select-quartiers" 
		name="agenda-filters-quartiers" 
		placeholder="Tous les quartiers" 
		options={SELECTS['quartiers']} 
		onChange={OnChangeFilterQuartier}
		value={AgendaFilterActiveQuartier}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default AgendaFormFilterQuartiers
