import React from 'react'
import AgendaFormFilterDatesButtons from './AgendaFormFilterDatesButtons'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const AgendaFormFilterDates = ({FilterDateIsOpen, OnClickDatePickerOpenClose, FilterDateCurrent, OnClickDatePickerReset, OnClickDatePickerThisDay, OnClickDatePickerThisWeek, OnClickDatePickerThisWeekend, datePickerShowStartValue, datePickerStartValue, OnChangeDatePickerStart, datePickerShowEndValue, datePickerEndValue, OnChangeDatePickerEnd}) =>
	<div className={ FilterDateIsOpen ? 'agenda-filters-dates isOpen' : 'agenda-filters-dates ' }>
		<div className="agenda-filters-dates-content">
			<div className="agenda-filters-dates-header">
				<div className="agenda-filters-dates-current">
					<span className="agenda-filters-dates-current-name" onClick={OnClickDatePickerOpenClose} onKeyPress={OnClickDatePickerOpenClose} role="button" tabIndex={0}>{FilterDateCurrent}</span>
				</div>
			</div>
			<div className="agenda-filters-dates-popin">
				<AgendaFormFilterDatesButtons 
					OnClickDatePickerReset={OnClickDatePickerReset} 
					OnClickDatePickerThisDay={OnClickDatePickerThisDay}
					OnClickDatePickerThisWeek={OnClickDatePickerThisWeek}
					OnClickDatePickerThisWeekend={OnClickDatePickerThisWeekend}
				/>
				<div className="agenda-filters-dates-datepickers">
					<div className="agenda-filters-dates-datepickers-mini-cal">
						<p>Date de début</p>
						<DatePicker 
							selected={datePickerShowStartValue ? datePickerStartValue : null} 
							onChange={OnChangeDatePickerStart} 
							dateFormat="YYYY-MM-DD"
							inline
							selectsStart 
							locale="fr"
						/> 
					</div>
					<div className="agenda-filters-dates-datepickers-mini-cal">
						<p>Date de fin</p>
						<DatePicker 
							selected={datePickerShowEndValue ? datePickerEndValue : null} 
							onChange={OnChangeDatePickerEnd} 
							dateFormat="YYYY-MM-DD"
							inline
							selectsEnd 
							locale="fr"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

export default AgendaFormFilterDates
