import React from 'react';
import AgendaFormFilterCats from './AgendaFormFilterCats';
import AgendaFormFilterPublics from './AgendaFormFilterPublics';
import AgendaFormFilterQuartiers from './AgendaFormFilterQuartiers';
import AgendaFormFilterParticipation from './AgendaFormFilterParticipation';
import AgendaFormFilterDates from './AgendaFormFilterDates';

const AgendaForm = ({AgendaFilterActiveOther, AgendaFilterActivePublic, AgendaFilterActiveQuartier, onSubmit, OnChangeFilterCats, OnChangeFilterPublic, OnChangeFilterQuartier, OnChangeFilterParticipation, OnClickDatePickerOpenClose, FilterDateIsOpen, FilterDateCurrent, OnClickDatePickerReset, OnClickDatePickerThisDay, OnClickDatePickerThisWeek, OnClickDatePickerThisWeekend, datePickerStartValue, datePickerShowStartValue, OnChangeDatePickerStart, datePickerEndValue, datePickerShowEndValue, OnChangeDatePickerEnd, ...rest}) => (
	<form id="agenda-filters" className="generic-filters agenda-filters" onSubmit={onSubmit}>
		<div className="generic-filters-header agenda-filters-header">
			<h1 className="generic-filters-header-title agenda-filters-header-title"><span>Sortir</span> à Roubaix</h1>
			<div className="agenda-filters-header-what">
				<div className="agenda-filters-header-what-group">
					<div className="agenda-filters-text" ><input id="agenda-filters-text" {...rest} /></div>
					<div className="agenda-filters-submit" onClick={onSubmit} onKeyPress={onSubmit} role="button" tabIndex={0}>
						<input id="agenda-filters-submit" type="submit" value="Go" />
					</div>
				</div>
			</div>
		</div>
		<AgendaFormFilterCats 
			AgendaFilterActiveOther={AgendaFilterActiveOther} 
			OnChangeFilterCats={OnChangeFilterCats} 
		/>
		<AgendaFormFilterDates 
			OnClickDatePickerOpenClose={OnClickDatePickerOpenClose}
			FilterDateIsOpen={FilterDateIsOpen}
			FilterDateCurrent={FilterDateCurrent}
			OnClickDatePickerReset={OnClickDatePickerReset}
			OnClickDatePickerThisDay={OnClickDatePickerThisDay}
			OnClickDatePickerThisWeek={OnClickDatePickerThisWeek}
			OnClickDatePickerThisWeekend={OnClickDatePickerThisWeekend}
			datePickerStartValue={datePickerStartValue} 
			datePickerShowStartValue={datePickerShowStartValue} 
			OnChangeDatePickerStart={OnChangeDatePickerStart} 
			datePickerEndValue={datePickerEndValue} 
			datePickerShowEndValue={datePickerShowEndValue} 
			OnChangeDatePickerEnd={OnChangeDatePickerEnd}
		/>
		<AgendaFormFilterPublics 
			AgendaFilterActivePublic={AgendaFilterActivePublic} 
			OnChangeFilterPublic={OnChangeFilterPublic}
		/>
		<AgendaFormFilterQuartiers 
			AgendaFilterActiveQuartier={AgendaFilterActiveQuartier} 
			OnChangeFilterQuartier={OnChangeFilterQuartier}
		/>
		<AgendaFormFilterParticipation 
			OnChangeFilterParticipation={OnChangeFilterParticipation}
		/>
	</form>
)

export default AgendaForm;
