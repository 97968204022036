import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as loadingActions from '../../../state/actions/loading'

import Config from '../../../config/Config.json'
import Config_local from '../../../config/Config_local.json'
import AgendaSingleQueryConfig from './AgendaSingleQueryConfig.json'
import AgendaSingleItem from "./AgendaSingleItem"

const config = {...Config, ...Config_local}
const agendaSingleQuery = {...AgendaSingleQueryConfig.queryDefault}

class AgendaSingle extends Component {
	_isMounted = false
	
	constructor(props, context) {
		super(props, context)

		const {urlSingle} = this.props

		// Liste des filtres actifs
		this.state = {
			urlSingle: urlSingle ? urlSingle.trim() : '',
			post: []
		}
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn} = this.props

		this.launchSingle()
		loadingOn('AgendaSingle')
	}

	componentWillUnmount() {
		const {loadingOff} = this.props

		loadingOff('AgendaSingle')
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevState.urlSingle !== this.state.urlSingle && this.launchSingle()
	}

	launchSingle() {
		const {loadingOff} = this.props
		const {urlSingle} = this.state

		if(urlSingle.length) {

			agendaSingleQuery.query.bool.must[1].bool.must[0].match.post_name = urlSingle
			agendaSingleQuery.query.bool.must[1].bool.must[1].match.permalink = "https://openagenda.com/roubaix/events/" + urlSingle
			// console.log(JSON.stringify(agendaSingleQuery))
			fetch(config.esServer, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'tokenesinouit': 'spS4brB5EYX2tu8exUPFZDXvAr0ZD7u9whzxPfGqGvGWGcRMDqHxH04axVSj1k0a6TlJTAagi4Sz3yKg5NNPi0F7m2LUSFvZCCQYzMdTS3WXxAXtATBbWVhXwMpSS79PycfnwdabDVsSWttOAWkyCK67DWXq5g1HYTwH8toyF9HyZwrOmttRnYQSf4qnzUJJWYyTAAbvBrdegXFweIdt5IRX0S5GL8ARk3Kz30GWR3tQpxNwrmid9nSe7SQxopoVC7Kl9gR5zQY7ijpeohaEqJZsvg3hN400000iGm8nKB0vKL2i9nPFaLIzVXV6c4RKuWBwzXrBOiCfyAtjyZAe7dpEIuqz82cIh80wiRaVCdOQ9YtE355IqgpiOytsSKTtw6HuWaGjopqjRe9pzixWJWGhLRGPB9iZRe1TP78HuiPdWKHUGWxB5MHroMoTbFqfyLXo9kk4djQA2GuAsTLI8VYOCDwCAcfJOPMtJIwa356KHK8Ma4VCFYgWqJ2sZtT77pOszI8deVGb9M1DXWD9nPhsGJSqDt4K8TRzhcVmgn8sUamx8TFyPa1E06Gh2QUCyXTfjOP0LzSgAdb2gaOohuSOfYibjfoE3aCSnevQAflmGoCqsFphlYNCTzYQhdO2'
				},
				method: "POST",
				body: JSON.stringify(agendaSingleQuery)
			}).then(response => {
				if (response.status && response.status > 200 ) {
					console.warn(response)
				} else {
					return response.json()
				}
			}).then(({hits}) => {
				this._isMounted && this.setState({ 
					post: hits.hits[0]
				})
				loadingOff('AgendaSingle')
			}).catch(function(error) {
				console.error("error", error)
			})
		}
	}

	render(){
		const {location} = this.props
		const {post} = this.state

		return (
			<div>
				<div className="offline-fallback-fix-no-idea-why"></div>
				<div className="agenda-single">
					<AgendaSingleItem 
						shareUrl={location.href}
						postItem={post}
					/>
				</div>
			</div>
		)
	}
}

export default connect(null, loadingActions)(AgendaSingle)
